import { Injectable } from '@angular/core';
import { TranslateService } from '@codeandweb/ngx-translate';

@Injectable({ providedIn: 'root' })
export class TranslateGenericApiValidationError {
  constructor(private translateService: TranslateService) {}

  /**
   * translate generic api validation errors result
   * e.g.:
   {
      "email": [
          "#validation.unique# #validation.email#"
      ],
      "user_details.first_name": [
          "#validation.min# #validation.firstName# #20#"
      ],
      "user_details.last_name": [
          "#validation.min# #validation.lastName# #20#"
      ]
    }
   * @param e
   * @returns string with error messages
   */
  translate = (e: any): string => {
    const errors: string[] = [];
    Object.values(e.error).forEach((t: any) => {
      let trans = '';
      const regex = /(?:#)(\S+?)(?:#)/gm;
      const parts = [...t.pop().matchAll(regex)];
      if (parts) {
        const fieldValues = {
          field: parts[1][1] ? this.translateService.instant(parts[1][1]) : '',
          value: parts?.[2]
            ? this.translateService.instant(parts[2][1])
            : this.translateService.instant(parts[0][1]),
        };
        trans = this.translateService.instant(parts[0][1], fieldValues);
      }
      errors.push(trans);
    });
    return errors.join(' | ');
  };
}
